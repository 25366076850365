// Common
import { Modal } from "bootstrap";

// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";

// Components
import { Slider } from "../../components/slider/slider";
import { Share } from "../../components/share/share";
import { Menu } from "../../components/menu/menu";
import { HeroVideo } from "../../components/herovideo/herovideo";

//import { Header } from "../../components/header/header";
import Swiper from "swiper";

// Registering components
//Component.register("Header", Header);
Component.register("Slider", Slider);
Component.register("Share", Share);
Component.register("Menu", Menu);
Component.register("HeroVideo", HeroVideo);

init();

document.addEventListener('DOMContentLoaded', () => {




  $(`.c-menu__nav--item--haschild > a`).on('click', function(){
    event.preventDefault();
    if (!$(this).parent(`.c-menu__nav--item--haschild`).hasClass('open')){
      $(`.c-menu__nav--item--haschild`).removeClass('open');
      $(this).parent(`.c-menu__nav--item--haschild`).addClass('open');
    }
    else {
      $(this).parent(`.c-menu__nav--item--haschild`).removeClass('open');
    }
  });

  // Al caricamento iniziale seleziona lo slider con tab attiva
  var activeSlider = $(`.c-sectionroster__tab--item.active`).attr("data-attr");
  $(`.c-sectionroster__slider`).each(function(e){
    var sliderattr = $(this).attr("data-attr");
    if ( sliderattr == activeSlider ) {
      $(this).addClass("open");
    }
    else {
      $(this).removeClass("open");
    }
  });

  // Sezione news in Homepage: L'altezza del box e lo spostamento verso l'alto dipendono dalla grandezza dell'immagine e da quella del contenuto della card
  var maxH=0;
  var viewportWidth = $(window).width();
  $(`.c-card1__content`).each( function() {
    var innerHeight = $(this).innerHeight();
    if ( innerHeight > maxH ) {
      maxH = innerHeight;
    }
  });
  var imageH = $(`.c-card1__img`).height();
  if ( viewportWidth < 992 ) {
    $(`.c-sectionslider__inner`).css('height', imageH + 144 );
  }
  else {
    $(`.c-sectionslider__inner`).css('height', imageH + 244 );
  }
  $(`.zaux-pos-boxnews`).css('transform', 'translateY(-' + maxH + 'px)');

 // On resize
  $(window).resize(function(){
    var viewportWidth = $(window).width();
    $(`.c-sectionslider`).css('transform', '');
    var maxH=0;
    $(`.c-card1__content`).each( function() {
      var innerHeight = $(this).innerHeight();
      if ( innerHeight > maxH ) {
        maxH = innerHeight;
      }
    });
    var imageH = $(`.c-card1__img`).height();
    if ( viewportWidth < 992 ) {
      $(`.c-sectionslider__inner`).css('height', imageH + 144 );
    }
    else {
      $(`.c-sectionslider__inner`).css('height', imageH + 244 );
    }
    $(`.zaux-pos-boxnews`).css('transform', 'translateY(-' + maxH + 'px)');

  });


 // On Scroll
  $(window).scroll(function(){
    const currentScroll = $(window).scrollTop();
    if ( !$("body").hasClass('zaux-menu-active') ){
      if (currentScroll > 0) {
        $(".c-header__logo--full").fadeOut(300);
        $(".c-header__logo").addClass("scroll-down");
        $(".c-header__inner").addClass("top-0");
        $(".c-header__nav").addClass("zaux-pos-header-nav");
        $(".c-header__insert").addClass("zaux-pos-insert");


        $(".c-menu__inner").removeClass("zaux-pos-menu-custom1");
        $(".c-menu__inner").addClass("zaux-pos-menu-custom2");
      }
      else {
        $(".c-header__logo--full").fadeIn(300);
        $(".c-header__logo").removeClass("scroll-down");
        $(".c-header__inner").removeClass("top-0");
        $(".c-header__nav").removeClass("zaux-pos-header-nav");
        $(".c-header__insert").removeClass("zaux-pos-insert");

        $(".c-menu__inner").removeClass("zaux-pos-menu-custom2");
        $(".c-menu__inner").addClass("zaux-pos-menu-custom1");
      }
    }
    else {
      $(".c-header__logo").css("display","none");
    }
  });


  // Funzioni al click
    // Click su tab pagine secondarie nella sezione introduttiva
    $(`.c-introsection__content--nav--item`).click(function(){
      if (!$(this).hasClass('active')){
        $(`.c-introsection__content--nav--item`).removeClass('active');
        $(this).addClass('active');
      }
    });
    // Click su tab sezione roster per cambio slider squadra
    $(`.c-sectionroster__tab--item`).click(function(){
      event.preventDefault();
      if (!$(this).hasClass('active')){
        $(`.c-sectionroster__tab--item`).removeClass('active');
        $(this).addClass('active');
      }
      var activeSlider = $(`.c-sectionroster__tab--item.active`).attr("data-attr");
      $(`.c-sectionroster__slider`).each(function(e){
        var sliderattr = $(this).attr("data-attr");
        if ( sliderattr == activeSlider ) {
          $(this).addClass("open");
        }
        else {
          $(this).removeClass("open");
        }
      });
    });
    //Click footer slider
      $(`.c-footer__menu--col--haschild > a`).click(function(){
        event.preventDefault();
        if (!$(this).parent(`.c-footer__menu--col--haschild`).hasClass('open')){
          $(`.c-footer__menu--col--haschild`).removeClass('open');
          $(this).parent(`.c-footer__menu--col--haschild`).addClass('open');
        }
        else {
          $(this).parent(`.c-footer__menu--col--haschild`).removeClass('open');
        }
      });

    // Click su card tab sezione storia per cambio contenuto testuale
    $(`.c-card2--history`).click(function(){
      event.preventDefault();
      if (!$(this).hasClass('active')){
        $(`.c-card2--history`).removeClass('active');
        $(this).addClass('active');
      }
      var activeTab = $(`.c-card2--history.active`).attr("data-value");
      $(`.c-sectionhistory__content`).each(function(e){
        var sliderattr = $(this).attr("data-value");
        if ( sliderattr == activeTab ) {
          $(this).addClass("open");
        }
        else {
          $(this).removeClass("open");
        }
      });
    });

    $('[data-fancybox="gallery"]').fancybox({
      openEffect  : 'fade',
      closeEffect : 'fade',
      helpers: {
          title : {
              type : 'inside'
          },
          overlay: {
              locked: false // Elimina il problema dell scroll verso il top all'apertura
          },
          media : {
          }
      }
  });

  $('[data-fancybox*="multimedia"]').fancybox({
    openEffect  : 'fade',
    closeEffect : 'fade',
    helpers: {
        title : {
            type : 'inside'
        },
        overlay: {
            locked: false // Elimina il problema dell scroll verso il top all'apertura
        },
        media : {
        }
    }
});


});
